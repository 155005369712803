<template>
  <div style="flex: 1">
    <Iheader></Iheader>
    <main>
      <div class="wrapper clrfix">
        <section class="content ">
          <h1 style="color: rgba(255, 255, 255, 1);font-size: 24px;">{{pageTitle}}</h1>
          <section class="content-box" v-html="pageContent">
          </section>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
import Iheader from "@/components/header.vue";
export default {
  name: "readPage",
  components: {
    Iheader,
  },
  data() {
    return {
      page: "about",
      pageTitle:'loading',
      pageContent:''
    };
  },
  created() {

  },
  
  watch: {
    "$route.query": {
      immediate: true,
      handler() {
        let { page } = this.$route.query;
        this.page = page;
        this.loadHtml();
      },
      deep: true,
    },
  },
  updated(){
  //  this.fontcolor()
  },
  
  methods: {  
   fontcolor(){
   // 获取所有类名为 "content-box" 的元素
    let contentBoxes = document.querySelectorAll('.content-box')

// 遍历每个 "content-box" 元素
contentBoxes.forEach(contentBox => {
    // 获取当前 "content-box" 元素内部的所有 "div" 元素
    let divs = contentBox.querySelectorAll('div');

    // 遍历每个 "div" 元素
    divs.forEach(div => {
        // 获取当前 "div" 元素内部的 "font" 元素
        let fontElement = div.querySelector('font');

        // 这里可以对获取到的 "font" 元素进行操作
        if (fontElement) {
          var text = fontElement.textContent;

    console.log(fontElement);
    if(text!=''&&text!=null&&text!=undefined&&text!=' '){
      fontElement.style.color = 'white';
      fontElement.style.padding = '5px 10px';
      fontElement.style.backgroundColor = 'rgba(71, 144, 246, 1)';
      fontElement.style.borderRadius = '16px 16px 16px 0px';
    }
            // Do something with fontElement
            fontElement.style.color = 'white';
            fontElement.style.padding = '5px 10px';
            fontElement.style.backgroundColor = 'rgba(71, 144, 246, 1)';
            fontElement.style.borderRadius = '16px 16px 16px 0px';
        }
    });
}) 
    },
    loadHtml() {
      this.$api.getPageContent({
        page:this.page,
        lang:this.$cookies.get('lang'),
      }).then((res) => {
        let {header,content} = res.data;
        this.pageTitle = header;
        this.pageContent = content;
      });
    }
  },
};
</script>

<style lang="less" scoped>
@media screen and (max-width: 1200px) {
  .content-box{
    width: calc(100% - 94px);
  padding: 32px ;
  background-color: rgba(35, 58, 106, 1);
  border-radius: 6px;
  margin: 0 auto ;
  box-sizing:content-box 

   }
   .wrapper{
    padding: 0px !important;
    max-width: 100% !important; 
   }
}
 
@media screen and (min-width: 1201px) {
  .content-box{
    width: 1136px;
  padding: 32px;
  background-color: rgba(35, 58, 106, 1);
  border-radius: 6px;
   }
   .wrapper{
    // padding: 0px;
    max-width: 1200px; 
   }
}
  
</style>